import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  TestIdPartial,
  AccessibilityLabelPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-alert.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        code={snippet}
        platform="react-native"
        gitHubLink="alert"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="actions" types={['Action[]']}>
            <Text>
              One or more buttons to be displayed. Each action has the following
              shape:
            </Text>
            <List type="unordered">
              <li>
                <code>buttonType</code> (string) - the type of button
              </li>
              <li>
                <code>text</code> (string) - the text describing the action
              </li>
              <li>
                <code>status</code> (string) - the status of the button
              </li>
              <li>
                <code>testID</code> (string) - a unique string to control this
                action in automated tests
              </li>
              <li>
                <code>accessibilityLabel</code> (string) - the text that's read
                by the screen reader when the user interacts with the button.
              </li>
              <li>
                <code>onPress</code> (func) - responds to the action being
                pressed.
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isOpen" types={['boolean']} isRequired>
            <Text>Determines whether or not the alert is displayed.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']} isRequired>
            <Text>The main content text displayed in the alert.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onAfterOpen" types={['() => void']}>
            <Text>Called when the alert is opened.</Text>
          </PropListItem>

          <PropListItem name="onCancel" types={['() => void']}>
            <Text>
              Called when the alert is dismissed by swipe, back button press or
              tapping the scrim.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="alert" />

          <TestIdPartial componentName="alert" />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from a{' '}
              <Link href="https://reactnative.dev/docs/view">view</Link>{' '}
              component.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Dialogs / Alert"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
