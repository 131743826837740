import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ClassnamePartial,
  QaIdPartial,
  CodeSnippet,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Alert = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Alert;
    return <Component {...props} />;
  },
});

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/alert.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Alert"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/overlays-dialogs--docs#alert"
      />
      <CodeSnippet
        scope={{ React: React, Alert: Alert, Button: Button }}
        code={snippet}
        platform="react"
        gitHubLink="alert"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ActionPartial
            showButtonType
            showText
            showQaid
            showOnClick
            showStatus
            showHref
            showTarget
            showTooltip
            showDisabled
          />

          <ClassnamePartial componentName="alert" />

          <PropListItem name="isOpen" types={['boolean']} isRequired>
            <Text>Determines whether or not the alert is displayed.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']} isRequired>
            <Text>The main content text displayed in the alert.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onAfterOpen" types={['() => void']}>
            <Text>Called when the alert is opened.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="alert" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Dialogs / Alert"
      />
    </PlatformTab>
  );
};

export default WebTab;
